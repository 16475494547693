<template>
   <div class="main1">
      <div class="top">
         <Header :menuList="menuList" 
         :isActive="$store.state.isActive"
         @translateEn='translateEn' 
         @translateChinese='translateChinese'
         @goHome="goHome" 
         @toastTextFun="toastTextFun"
         @handleClick="handleClick"></Header>
      </div>
      <div class="">
         <router-view></router-view>
      </div>
      <div class="Footer">
       <Footer></Footer>
      </div>
      
      <!-- 没安装Meta浏览器 -->
      <div class="indexalert">
         <Alert v-model="$store.state.showInfo">
            <div class="pop_content rcc_colum t_c">
               <h1 v-if="isMobile === true" class="Mh1">{{$t("unit.unit14")}}</h1>
               <img src="https://diao-1305953606.cos.ap-hongkong.myqcloud.com/fox.png" class="ic_fox" alt="">
               <div>
                  <p v-if="isMobile === false" class="font_amatic c_f question">{{$t("unit.unit14")}}</p>
                  <div class="mt30">
                     <p>{{$t("unit.unit15")}}</p>
                     <p>{{$t("unit.unit16")}}</p>
                  </div>
                  <a href="https://metamask.io/" target="/">
                  <button class="btns font_amatic" @click="handleInstall">{{$t("unit.unit17")}}</button></a>
               </div>
            </div>
         </Alert>
      </div>
      <!-- 链接钱包 -->
       <div class="indexalert">
        <Alert v-model="$store.state.wallet_show" :alertwidth="false">
            <div class="conalert detailbox">
               <img src="@/assets/image/net26.png" alt="">
               <h2>{{$t("index.Part1text30")}}</h2>
               <p>{{$t("index.Part1text31")}}</p>
                <div class="info">
                  <!-- <button @click="TryAgain">{{$t("button.button12")}}</button> -->
                </div>
            </div>
        </Alert>
       </div>
       
       <!-- 网络错误 -->
        <div class="Getnetwork">
            <Alert
                v-model="$store.state.net_show"
                :alertwidth="false"
            >
                <div class="conalert detailbox">
                    <img
                        src="@/assets/image/net26.png"
                        alt=""
                    >
                    <h2>{{$t("unit.unit22")}}</h2>
                    <p>{{$t("unit.unit23")}}</p>
                    <div class="info">
                        <!-- <button @click="TryAgain">{{$t("button.button12")}}</button> -->
                    </div>
                </div>
            </Alert>
        </div>
   </div>
</template>

<script>
import store from '../store';
import i18n from 'vue-i18n';
import Web3 from 'web3';
import { Locale,Toast } from 'vant';
import {Api} from '@/api/api';

import {networkChange} from '@/utils/index.js'
import config from "@/assets/config/index"
export default {
  components: {
    Header: (resolve) => require(["@/components/menu"], resolve),
    Alert: (resolve) => require(["@/components/Alert"], resolve),
    Footer: (resolve) => require(["@/components/Footer"], resolve),
    
  },
  	data() {
      return {
         // Accountalert:false,
         isloginconnect:false,
         McPursetext:'',
         znshow:'en-us',
         isMobile:'false',
         connectPursetext:'',
         accounts:'',
         Token:'',
         diaoNumberNum:'',
         lang: localStorage.getItem('lang'),
         // isActive:this.$store.state.isActive,
         menuList: [
               { name: 'Home', path: "index" },
               { name: 'What is BSCM?', path: "BSCM" },
               { name: 'Story', path: "Story" },
               { name: 'Tokenomic', path: "Tokenomic" },
               // { name: 'Buy $BSCM', path: "Buy" },
         ], 
      }
   },
   methods:{
      goHome(puth){
         this.$router.push(puth);
         this.$store.state.isActive = -1
      },
      handleClick(index,path){
         console.log(index,path)
         this.$store.state.isActive = index;
         console.log(document.getElementById(path))
         
      },
      // 链接钱包
      async toastTextFun(){
         console.log('414')
         this.msg = "Connecting wallet..."
         //  this.$Toast("COMING SOON")
         if (typeof window.ethereum === 'undefined') {
            this.$store.state.wallet_show = true
            this.$store.dispatch("InstallVal", true);
            console.log(this.$store.state.install)
            localStorage.setItem('install',this.$store.state.install)
            localStorage.removeItem("accounts");
            localStorage.removeItem("gasPrice");
            this.msg = ''
            return;
            // Toast('请确保您的浏览器已经安装了MetaMask插件')
            } else {
            console.log(window.ethereum)
            console.log(ethereum)
            this.$store.state.wallet_show = false
            localStorage.setItem('install',this.$store.state.install)
            this.$store.dispatch("InstallVal", true);
            try {
               console.log("ethereum",ethereum)
               ethereum = window.ethereum
               await ethereum.enable()
               location.reload();
               this.msg = '';
            } catch (error) {
               this.$store.state.wallet_show = true
               this.$store.dispatch("InstallVal", false);
               console.error('User denied account access')
               this.msg = ''
            }
         }
      },

      // changeAlert(){
      //    this.$store.state.accountAlert = false
      // },
      //绑定diaonum
      bindDiaoBum(){
         let token = window.localStorage.getItem("token");
         this.accounts = window.localStorage.getItem("accounts")
         console.log(this.accounts)
         if(this.accounts === 'undefined' ||this.accounts === null ||this.accounts === ''){
               this.$store.state.accountAlert = true
               this.$store.commit("showLoading",false);
         }
         // else if(token === null ||token === 'undefined' ){
         //     Toast("Please log in first");
         //     this.$store.commit("showLoading",false);
         // }
         else{
            console.log(this.diaoNumberNum)
            let regex =/^[0-9]\d{4}$/;
            let DiaoNumber =regex.test(this.diaoNumberNum)
            console.log(DiaoNumber)
            if(!DiaoNumber){
               Toast(this.$t("mine.Part1text48"));
            }else{
               Api.bangDing({
                  diaoNumber: this.diaoNumberNum,
                  owner: this.accounts
               }).then((res)=>{
                  console.log(res)
                  if(res.code === 0){
                     Toast(this.$t("mine.Part1text49",{ mag:this.diaoNumberNum }))
                     // Toast(`恭喜您，${this.diaoNumberNum}账号绑定成功`);
                     localStorage.setItem("diaoNumber",this.diaoNumberNum)
                     location.reload();
                  }else{
                     let lang = window.localStorage.getItem("lang")
                     if(lang === 'zh-cn'){
                     Toast(res.msg);
                     }else{
                     Toast(res.msgEn);
                     }
                  }
               })
            }
         }
      },
      // connectWallet(){
      //    connetAccount();
      // },
      TryAgain(){
         console.log(config.hexNetId,'===hexNetId')
         networkChange(config.hexNetId)
      },
      handleInstall(flag){
         console.log('Install MetaMask')
      },
      translateChinese () {
         this.lang = "zh-cn";
         this.znshow = "zh-cn"
         this.$i18n.locale = 'zh-cn';
         localStorage.setItem('lang','zh-cn');
         location.reload();
         console.log("中文");
      },
      translateEn(){
         this.lang = "en-us";
         this.znshow = "en-us"
         this.$i18n.locale = 'en-us';
         localStorage.setItem('lang','en-us');
         location.reload();
      }, 
      handleChange() {
         try{
            ethereum = window.ethereum
            ethereum.on("networkChanged", (networkIDstring)=> {
               location.reload();
            });
            ethereum.on("chainChanged", (networkIDstring)=> {
               location.reload();
            });
            ethereum.on("accountsChanged", (accounts)=> {
               location.reload();
            });
         }catch (error) {
           console.log(error)
         }
         
      },     
   },
   created(){
      this.isMobile = this.$utils.isMobile();
      
   },
   mounted(){
      this.handleChange();
   },
}
</script>

<style lang="less" scoped>

@media screen and (max-width: 1200px) {
// .contanier{
//    padding: 0px 15px;
// }
}
@media screen and (max-width: 768px) {
   /deep/.Getnetwork .alert {
        width: 22.5rem !important;
        height: 409px;
        border-radius: 8px;
        .info {
            button {
                margin-bottom: 0px !important;
            }
        }
    }
   /deep/.indexalert .alert,/deep/.alert{
        width: 22.5rem;
      //   height: 14.285rem;
        .pop_content{
           img{
              width: 70px;
           }
           padding: 0px 20px;
        }
        .Mh1{
           font-size: 18px;
           line-height: 28px;
           margin: 10px auto 30px;
        }
    }
    .tipstext{
       margin-top: 20px;
       font-size: 14px;
       line-height: 24px;
    }
    button{
       min-width: 146px;
       height: 38px;
       line-height: 38px;
       background: #F8BA00;
       color: #000;
       font-size: 16px;
       margin: 60px auto 30px;
       border-radius: 3px;
    }
    .info{
        font-size:1rem;
        line-height: 20px;
        margin-top: 25px;
        margin-bottom: 10px;
    }
   .main1{
      overflow-x: hidden !important;
   }
    .wrapper{
      width: 100%;
      margin: 0 auto;
   }
   .h_nav{
      width: 100%;
      height: 2.85rem;
      padding-top:0px;
      
   }
   .contanier{
      height: auto;
      min-width: 100%;
      margin-top: 40px;
      margin: 0px auto;
   }
   .info{
      text-align: center;
      margin-top: 10px;
      button{
         min-width: 160px !important;
         height: 50px !important;
         border-radius: 6px;
         border: 1px solid #F8BA00;
         color: #F8BA00 !important;
         font-size: 18px;
         // margin-bottom: 40px;
         margin: 0px auto 30px;
         cursor: pointer;
         margin-right: 40px;
         background: none !important;
         padding: 0px 10px !important;
      }
      :last-child{
         margin-right: 0px;
         background: #F8BA00 !important;
         color: #000 !important;
      }
   }
   .conalert{
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      img{
         width: 123px;
         height: 100px;
         margin: 20px auto 0px;

      }
      h2{
         // margin-top: 15px;
         font-size: 18px;
         line-height: 25px;
         // height: 25px;
         margin-bottom: 20px;
         max-width: 200px;
         margin: 15px auto 15px;
      }
      p{
            font-size: 14px;
            line-height: 20px;
            color: #CCCCCC;
            margin: 20px auto 0px;
            padding: 0px 1rem;
      }
      }
      .backNav{
         position: relative;
         z-index: 2001;
      }
}
@media screen and (min-width: 769px) {
   .wrapper{
      min-width: 1200px;
      max-width: 1920px;
      width: 100%;
      margin: 0 auto;
      color: #ccc;
   }
   .headbackimg{
   min-width:1200px;
   background: url(https://diao-1305953606.cos.ap-hongkong.myqcloud.com/en/wallSt/backfloor.png);
   background-size: 100%;
}
.main1{
   height: 100%;
   min-height: 100%;
   // display: flex;
   // flex-direction: column;
   // position: relative;
   // z-index: -1;
}
.main1>div{
   flex: 1;
}
.headers{
   width: 100%;
   position: absolute;
   top: 0px;
   left: 0px;
   z-index: 2000;
}
.h_nav{
   padding-top: 20px;
   width: 1200px;
   margin: 0px auto;
}
.contanier{
   width: 100%;
   margin-top: 165px;
   margin: 0px auto;
   // background: #000;
}
.pop_content{
   height: 100%;
   margin: 25px 60px;
   .ic_fox{
      width: 80px;
   }
   .question{
      font-size: 28px;
      // margin: 20px 0;
   }
   .btns{
      width: 200px;
      height: 50px;
      font-size: 28px;
      margin: 30px auto 0px;
      color: #000;
      background: #F8BA00;
      border-radius: 6px;
   }
}
.info{
  text-align: center;
  padding-top: 86px;
//   padding-bottom: 50px;
  button{
    min-width: 160px !important;
    height: 50px !important;
    border-radius: 6px;
    border: 1px solid #F8BA00;
    color: #F8BA00 !important;
    font-size: 18px;
    margin-bottom: 40px;
    cursor: pointer;
    margin-right: 40px;
    background: none !important;
    padding: 0px 10px;
  }
  :last-child{
    margin-right: 0px;
    background: #F8BA00 !important;
    color: #000 !important;
  }
}
.diaoNumbox{
     p{
        padding: 0px 48px;
     }
     .info{
        padding-top: 0px !important;
     }
  }
.conalert{
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  
  img{
    width: 123px;
    height: 100px;
    margin: 20px auto 0px;

  }
  h2{
     margin-top: 20px;
    font-size: 24px;
    line-height: 40px;
    height: 40px;
  }
  p{
      font-size: 18px;
      line-height: 28px;
      color: #CCCCCC;
      margin: 20px auto 0px;
  }
}
}
.usernum{
  width: 200px;
  margin: 20px auto 20px;
  input{
    color: #fff;
    width: 100%;
    height: 50px;
    border: 1px solid #666666;
    border-radius: 6px;
    padding: 0px 10px;
    background: #000;
  }
  /deep/.van-field{
    border-radius: 6px;
  }
}

</style>